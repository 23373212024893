export const RequestMap: Record<string, string> = {
  'weather': 'Weather',
  'known_for': 'Known for',
  'shopping': 'Shopping',
  'culinary_highlights': 'Culinary Highlights',
  'safety': 'Safety',
  'excursion_destinations': 'Excursion Destinations',
  'best_time_to_visit': 'Best time to visit',
  'route_to_destination': 'Route to destination',
  'poi': 'Points of Intrest',
  'seo': 'SEO Content',
  'faq': 'FAQs',
  'main_content_length': 'Main Content Length',
  'content_depth': 'Content Depth',
  'contractions': 'Contractions',
  'persona_groups': 'Persona Groups',
  'languages': "Languages",
  'airports': 'Airports',
  'transportation': 'Transportation from/to Airport',
  'whentobook': 'When to Book',
  'whentogo': 'When to Go',
  'navigate_destination': 'How to best navigate the destination',
  'gem_spots': 'Hidden Gem Travel Spots',
  'money_costs': 'Money and Costs',
  'what_to_see': 'What to see and do',
  'content_type': 'Content Type',
  'title': 'Title',
  'website_name': 'Website Name',
  'search_for': 'Name of Airline / Destination / Hotel'
}

export const languageMap: Record<string, string> = {
  'BG': 'Bulgarian',
  'CS': 'Czech',
  'DA': 'Danish',
  'DE': 'German',
  'EL': 'Greek',
  'ES': 'Spanish',
  'ET': 'Estonian',
  'FI': 'Finnish',
  'FR': 'French',
  'HU': 'Hungarian',
  'ID': 'Indonesian',
  'IT': 'Italian',
  'JA': 'Japanese',
  'KO': 'Korean',
  'LT': 'Lithuanian',
  'LV': 'Latvian',
  'NB': 'Norwegian',
  'NL': 'Dutch',
  'PL': 'Polish',
  'PT-BR': 'Portuguese (Brazilian)',
  'PT-PT': 'Portuguese',
  'RO': 'Romanian',
  'RU': 'Russian',
  'SK': 'Slovak',
  'SL': 'Slovenian',
  'SV': 'Swedish',
  'TR': 'Turkish',
  'UK': 'Ukrainian',
  'ZH': 'Chinese (simplified)',
}