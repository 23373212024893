export const languageMap: Record<string, string> = {
  'Bulgarian': 'BG',
  'Czech': 'CS',
  'Danish': 'DA',
  'German': 'DE',
  'Greek': 'EL',
  'Spanish': 'ES',
  'Estonian': 'ET',
  'Finnish': 'FI',
  'French': 'FR',
  'Hungarian': 'HU',
  'Indonesian': 'ID',
  'Italian': 'IT',
  'Japanese': 'JA',
  'Korean': 'KO',
  'Lithuanian': 'LT',
  'Latvian': 'LV',
  'Norwegian': 'NB',
  'Dutch': 'NL',
  'Polish': 'PL',
  'Portuguese (Brazilian)': 'PT-BR',
  'Portuguese': 'PT-PT',
  'Romanian': 'RO',
  'Russian': 'RU',
  'Slovak': 'SK',
  'Slovenian': 'SL',
  'Swedish': 'SV',
  'Turkish': 'TR',
  'Ukrainian': 'UK',
  'Chinese (simplified)': 'ZH',
}

export const selectorMap: Record<string, string> = {
  'weather': 'Weather',
  'known_for': 'Known for',
  'shopping': 'Shopping',
  'culinary_highlights': 'Culinary Highlights',
  'safety': 'Safety',
  'excursion_destinations': 'Excursion Destinations',
  'best_time_to_visit': 'Best time to visit',
  'route_to_destination': 'Route to destination',
  'poi': 'Points of Intrest',
  'seo': 'SEO Content',
  'faq': 'FAQs',
  'main_content_length': 'Main Content Length',
  'content_depth': 'Content Depth',
  'contractions': 'Contractions',
  'persona_groups': 'Persona Groups',
  'languages': "Languages",
  'airports': 'Airports',
  'transportation': 'Transportation from/to Airport',
  'whentobook': 'When to Book',
  'whentogo': 'When to Go',
  'navigate_destination': 'How to best navigate the destination',
  'gem_spots': 'Hidden Gem Travel Spots',
  'money_costs': 'Money and Costs',
  'what_to_see': 'What to see and do',
}