import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useRequestHandler } from '../util/useRequestHandler'
import { authAtom } from '../state/authState';
import { contentViewerDataAtom } from '../state/contentViewerState';

export const useContentViewerActions = () => {
  const { requestHandler } = useRequestHandler()
  const auth = useRecoilValue(authAtom);
  const setContentViewerData = useSetRecoilState(contentViewerDataAtom);

  const getContentData = async (contentId: string) => {
    try {
      const contentData = await requestHandler.get(`/contentx/getContent?apikey=${auth}&contentID=${contentId}`) 
      setContentViewerData(contentData.data)

    } catch (e) {
      console.log(e)
    }

  }

  const generateContent = async (contentId: string) => {
    try {
      const contentData = await requestHandler.post(`/contentx/contentRequestHandler?apikey=${auth}&contentID=${contentId}`)
    } catch (e) {
      console.log(e)
    }
  }

  return {
    getContentData,
    generateContent
  }
}
