import { useSetRecoilState } from 'recoil'
import { useRequestHandler } from '../util/useRequestHandler'
import { hotelListDataAtom } from '../state/contentGeneratorState';

export const useContentGeneratorActions = () => {
  const { requestHandler } = useRequestHandler()
  const setHotelListData = useSetRecoilState(hotelListDataAtom);

  const getHotelList = async () => {
    try {
      const hotelListData = await requestHandler.get(`/contentx/getHotelList`)
      setHotelListData(hotelListData.data)

    } catch (e) {
      console.log(e)
    }

  }

  return {
    getHotelList
  }
}
