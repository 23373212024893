import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Accordion, Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import './ContentGenerator.scss'
import { languageMap } from './Constants'
import { useRequestHandler } from '../util/useRequestHandler'

import { authAtom } from '../state/authState'
import { ContentXNav } from '../Components/ContentXNav';
import { router } from '../util/router';
import { useContentGeneratorActions } from '../Actions/useContentGeneratorAction';
import { hotelListDataAtom } from '../state/contentGeneratorState';

type ContentRequestProp = {
  type: string;
}

const ContentRequest = ({ type }: ContentRequestProp) => {
  const { requestHandler } = useRequestHandler()
  const auth = useRecoilValue(authAtom);
  const hotelListData = useRecoilValue(hotelListDataAtom);
  const navigate = router.navigate
  const [ isSeo, setIsSeo ] = useState(false)
  const [isSeoError, setIsSeoError] = useState(false)
  const [isRequiredError, setIsRequiredError] = useState(false)
  const [contentRequest, setContentRequest] = useState({
    title: '',
    search_for: '',
    website_name: '',
    poi: false,
    gem_spots: false,
    weather: false,
    known_for: false,
    shopping: false,
    culinary_highlights: false,
    safety: false,
    excursion_destinations: false,
    best_time_to_visit: false,
    route_to_destination: false,
    seo_keywords: {
      seo_keywords_1: '',
      seo_keywords_2: '',
      seo_keywords_3: '',
      seo_keywords_4: '',
    },
    faq: 0,
    faq_json: false,
    languages: [] as Array<string>,
    airports: false,
    transportation: false,
    whentobook: false,
    whentogo: false,
    navigate_destination: false,
    money_costs: false,
    what_to_see: false,
    seo_title_description: false,
    seo_kws: false,
    seo_wdf_idf: false,
    seo_wdf_idf_intention: false,
    seo_intention: 'Informational'
  })

  const handleChange = (value: string | boolean | number, type: string) => {
    if (type === 'languages') {
      const languageArray = contentRequest.languages.includes(value.toString())
        ? contentRequest.languages.filter(lang => lang !== value)
        : [...contentRequest.languages, value] as Array<string>

      setContentRequest({
        ...contentRequest,
        languages: languageArray,
      })
    } else if (type === 'seo_keywords_1' || type === 'seo_keywords_2' || type === 'seo_keywords_3' || type === 'seo_keywords_4') {
      setContentRequest({
        ...contentRequest,
        seo_keywords: {
          ...contentRequest.seo_keywords,
          [type]: value,
        }
      })
    } else {
      setContentRequest({
        ...contentRequest,
        [type]: value,
      })
    }
  }

  const getSeoKeywords = (seoKeywordsMap: any) => {
    const seoKeywords = []
    if (seoKeywordsMap.seo_keywords_1 !== '') {
      seoKeywords.push(seoKeywordsMap.seo_keywords_1)
    }
    if (seoKeywordsMap.seo_keywords_2 !== '') {
      seoKeywords.push(seoKeywordsMap.seo_keywords_2)
    }
    if (seoKeywordsMap.seo_keywords_3 !== '') {
      seoKeywords.push(seoKeywordsMap.seo_keywords_3)
    }
    if (seoKeywordsMap.seo_keywords_4 !== '') {
      seoKeywords.push(seoKeywordsMap.seo_keywords_4)
    }

    return seoKeywords
  }

  const handleSubmit = async () => {
    setIsSeoError(false)
    setIsRequiredError(false)
    if (contentRequest.title.trim() === '' 
      || contentRequest.website_name.trim() === '' 
      || (contentRequest.search_for.trim() === '' && type === 'Airlines')
    ) {
      setIsRequiredError(true)
      return null
    }
    if (isSeo) {
      if (contentRequest.seo_keywords.seo_keywords_1 === ''
        || contentRequest.seo_keywords.seo_keywords_2  === ''
        || contentRequest.seo_keywords.seo_keywords_3 === ''
        || contentRequest.seo_keywords.seo_keywords_4 === ''
      ) {
        setIsSeoError(true)
        return null
      }
    }
    const requestData = {
      ...contentRequest,
      seo_keywords: getSeoKeywords(contentRequest.seo_keywords),
      rewrite_main_content: contentRequest.seo_kws || contentRequest.seo_wdf_idf || contentRequest.seo_wdf_idf_intention,
      faq_json: contentRequest.faq_json,
      apiKey: auth,
      content_type: type,
    }

    try {
      const contentJob = await requestHandler.post('/contentx/createContentRequest', requestData)
      navigate(`/content-viewer/${contentJob.data.contentID}`)
    } catch (e) {
      console.log(e)
    }
  }
  const renderPlaceholder = () => {
    if (type === 'Airlines') {
      return 'Name of Airline '
    }
    if (type === 'Destination') {
      return 'Name of Destination'
    }
    if (type === 'Hotels Destination') {
      return 'Name of Destination'
    }
    if (type === 'Flights Destination') {
      return 'Name of Destination'
    }
    if (type === 'Hotel Detail Page') {
      return 'Name of Hotel'
    }
  }

  return (
    <>
      <Row style={{marginBottom: '20px'}}>
        <Col>
          <Button onClick={handleSubmit} variant="primary">Submit Request</Button>
        </Col>
      </Row>
      <Row style={{marginBottom: '20px'}}>
        <Col lg={6}>
          <Card style={{ padding: '20px' }}>
            <Card.Title style={{ marginBottom: '20px' }}>Content Parameters</Card.Title>
            <Form.Control
              value={contentRequest.title}
              onChange={(e) => handleChange(e.target.value, 'title')}
              placeholder="Project Name"
              style={{ marginBottom: '20px' }}
              isInvalid={isRequiredError && contentRequest.title === ''}
            />
            {type !== 'Airlines' && type !== 'Hotel Detail Page' && (
              <Form.Control
                value={contentRequest.search_for}
                onChange={(e) => handleChange(e.target.value, 'search_for')}
                placeholder={renderPlaceholder()}
                style={{ marginBottom: '20px' }}
                isInvalid={isRequiredError && contentRequest.search_for === ''}
              />
            )}
            {type === 'Hotel Detail Page' && (
              <Form.Select 
                style={{marginBottom: '20px'}} 
                onChange={(e) => handleChange(e.target.value, 'search_for')} 
                aria-label="Select Hotel"
                isInvalid={isRequiredError && contentRequest.search_for === ''}
              >
                <option value="">Select Hotel</option>
                {hotelListData.map((hotel) => {
                  return <option value={hotel}>{hotel}</option>
                })}
              </Form.Select>
            )}
            <Form.Control
              value={contentRequest.website_name}
              onChange={(e) => handleChange(e.target.value, 'website_name')}
              placeholder={type === 'Airlines' ? 'Airline Name' : 'Company Name'}
              isInvalid={isRequiredError && contentRequest.website_name === ''}
            />
          </Card>
          {(type !== "Airlines" && type !== "Hotel Detail Page") && (
            <Card style={{ padding: '20px', marginTop: '20px' }}>
              <Card.Title style={{ marginBottom: '20px' }}>Content Toggles</Card.Title>
              <Card.Body style={{padding: 0}}>
                <Form.Check
                  type="switch"
                  id="faq-switch"
                  label="Points of Interest"
                  checked={contentRequest.poi}
                  onChange={() => handleChange(!contentRequest.poi, 'poi')}
                />
                <Form.Check
                  type="switch"
                  id="faq-switch"
                  label="Hidden Gem Travel Spots"
                  checked={contentRequest.gem_spots}
                  onChange={() => handleChange(!contentRequest.gem_spots, 'gem_spots')}
                />
                <Form.Check
                  type="switch"
                  id="faq-switch"
                  label="Weather"
                  checked={contentRequest.weather}
                  onChange={() => handleChange(!contentRequest.weather, 'weather')}
                />
                {type === 'Destination' && (
                  <Form.Check
                  type="switch"
                  id="faq-switch"
                  label="What to see and do"
                  checked={contentRequest.what_to_see}
                  onChange={() => handleChange(!contentRequest.what_to_see, 'what_to_see')}
                  />
                )}
                {(type === 'Destination' || type === 'Hotels Destination') && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Known for"
                    checked={contentRequest.known_for}
                    onChange={() => handleChange(!contentRequest.known_for, 'known_for')}
                  />
                )}
                {(type === 'Destination' || type === 'Hotels Destination') && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Culinary Highlights"
                    checked={contentRequest.culinary_highlights}
                    onChange={() => handleChange(!contentRequest.culinary_highlights, 'culinary_highlights')}
                  />
                )}
                {(type === 'Destination' || type === 'Hotels Destination') && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Shopping"
                    checked={contentRequest.shopping}
                    onChange={() => handleChange(!contentRequest.shopping, 'shopping')}
                  />
                )}
                {(type === 'Destination' || type === 'Hotels Destination') && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Safety"
                    checked={contentRequest.safety}
                    onChange={() => handleChange(!contentRequest.safety, 'safety')}
                  />
                )}
                {(type === 'Destination' || type === 'Hotels Destination') && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Excursion Destination"
                    checked={contentRequest.excursion_destinations}
                    onChange={() => handleChange(!contentRequest.excursion_destinations, 'excursion_destinations')}
                  />
                )}
                {type === 'Hotels Destination' && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Best Time to Vist"
                    checked={contentRequest.best_time_to_visit}
                    onChange={() => handleChange(!contentRequest.best_time_to_visit, 'best_time_to_visit')}
                  />
                )}
                {type === 'Destination' && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Money and Coats"
                    checked={contentRequest.money_costs}
                    onChange={() => handleChange(!contentRequest.money_costs, 'money_costs')}
                  />
                )}
                {type === 'Destination' && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="How to best navigate the Destination"
                    checked={contentRequest.navigate_destination}
                    onChange={() => handleChange(!contentRequest.navigate_destination, 'navigate_destination')}
                  />
                )}
                {type === 'Flights Destination' && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Airports"
                    checked={contentRequest.airports}
                    onChange={() => handleChange(!contentRequest.airports, 'airports')}
                  />
                )}
                {type === 'Flights Destination' && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Transportation from / to Airport"
                    checked={contentRequest.transportation}
                    onChange={() => handleChange(!contentRequest.transportation, 'transportation')}
                  />
                )}
                {type === 'Flights Destination' && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="When to Book"
                    checked={contentRequest.whentobook}
                    onChange={() => handleChange(!contentRequest.whentobook, 'whentobook')}
                  />
                )}
                {type === 'Flights Destination' && (
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="When to Go"
                    checked={contentRequest.whentogo}
                    onChange={() => handleChange(!contentRequest.whentogo, 'whentogo')}
                  />
                )}                        
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col lg={6}>
          <Card style={{ padding: '20px' }}>
            <Card.Title style={{ marginBottom: '20px' }}>Content Selectors</Card.Title>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Languages</Accordion.Header>
                <Accordion.Body>
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {Object.keys(languageMap).map((langKey) => (
                      <Form.Check
                        style={{ marginLeft: '5px' }}
                        type="checkbox"
                        id={languageMap[langKey]}
                        label={langKey}
                        name="contentLanguage"
                        checked={contentRequest.languages.includes(languageMap[langKey])}
                        onChange={(e) => handleChange(languageMap[langKey], 'languages')}
                      />
                      ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>FAQs</Accordion.Header>
                <Accordion.Body>
                  <Form.Control
                    type="number"
                    placeholder="Amount of FAQs"
                    style={{ marginBottom: '20px' }}
                    value={contentRequest.faq}
                    onChange={(e) => handleChange(e.target.value, 'faq')}
                  />
                  <Form.Check 
                    type="switch"
                    id="faq-switch"
                    label="FAQs in JSON Format"
                    checked={contentRequest.faq_json}
                    onChange={() => handleChange(!contentRequest.faq_json, 'faq_json')}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>SEO Content</Accordion.Header>
                <Accordion.Body>
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Enable SEO"
                    checked={isSeo}
                    onChange={() => setIsSeo(!isSeo)}
                    style={{marginBottom: '20px'}}
                  />
                  {isSeoError && (
                    <div className="text-danger">
                      Please fill all 4 SEO keywords
                    </div>
                  )}
                  <Form.Control
                    placeholder="SEO Keyword"
                    style={{ marginBottom: '20px' }}
                    value={contentRequest.seo_keywords.seo_keywords_1}
                    onChange={(e) => handleChange(e.target.value, 'seo_keywords_1')}
                    disabled={!isSeo}
                    isInvalid={isSeoError}
                  />
                  <Form.Control
                    placeholder="SEO Keyword"
                    style={{ marginBottom: '20px' }}
                    value={contentRequest.seo_keywords.seo_keywords_2}
                    onChange={(e) => handleChange(e.target.value, 'seo_keywords_2')}
                    disabled={!isSeo}
                    isInvalid={isSeoError}
                  />
                  <Form.Control
                    placeholder="SEO Keyword"
                    style={{ marginBottom: '20px' }}
                    value={contentRequest.seo_keywords.seo_keywords_3}
                    onChange={(e) => handleChange(e.target.value, 'seo_keywords_3')}
                    disabled={!isSeo}
                    isInvalid={isSeoError}
                  />
                  <Form.Control
                    placeholder="SEO Keyword"
                    style={{ marginBottom: '20px' }}
                    value={contentRequest.seo_keywords.seo_keywords_4}
                    onChange={(e) => handleChange(e.target.value, 'seo_keywords_4')}
                    disabled={!isSeo}
                    isInvalid={isSeoError}
                  />
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Meta Title & Description"
                    checked={contentRequest.seo_title_description}
                    onChange={() => handleChange(!contentRequest.seo_title_description, 'seo_title_description')}
                    disabled={!isSeo}
                  />
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="Add Keywords"
                    checked={contentRequest.seo_kws}
                    onChange={() => handleChange(!contentRequest.seo_kws, 'seo_kws')}
                    disabled={!isSeo}
                  />
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="WDF/IDF"
                    checked={contentRequest.seo_wdf_idf}
                    onChange={() => handleChange(!contentRequest.seo_wdf_idf, 'seo_wdf_idf')}
                    disabled={!isSeo}
                  />
                  <Form.Check
                    type="switch"
                    id="faq-switch"
                    label="WDF/IDF with Intention"
                    checked={contentRequest.seo_wdf_idf_intention}
                    onChange={() => handleChange(!contentRequest.seo_wdf_idf_intention, 'seo_wdf_idf_intention')}
                    disabled={!isSeo}
                  />
                  {contentRequest.seo_wdf_idf_intention && (
                    <div style={{ marginTop: '10px', display: 'flex' }}>
                      <Form.Check
                        type="radio"
                        id="informational"
                        label="Informational"
                        name="contentLanguage"
                        checked={contentRequest.seo_intention === 'Informational'}
                        onChange={(e) => handleChange('Informational', 'seo_intention')}
                      />
                      <Form.Check
                        style={{ marginLeft: '20px' }}
                        type="radio"
                        id="transactional"
                        label="Transactional"
                        name="contentLanguage"
                        checked={contentRequest.seo_intention === 'Transactional'}
                        onChange={(e) => handleChange('Transactional', 'seo_intention')}
                      />
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export const ContentGeneratorPage = () => {
  const [key, setKey] = useState('Destination');

  const { getHotelList } = useContentGeneratorActions()

  useEffect(() => {
    getHotelList()
  }, [])
  
  return (
    <div>
      <ContentXNav />
      <Row style={{ marginTop: '80px' }}>
        <Col style={{ marginTop: '50px', marginLeft: '20px' }}>
          <Row className="g-0">
            <h1 style={{ color: '#fcc627' }}>Content Brief</h1>
          </Row>
        </Col>
      </Row>
      <Row className='g-0'>
        <Col style={{ margin: '30px', border: '1px solid #dee2e6', borderRadius: '7px' }}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => { if(k) { setKey(k)}}}
            className="mb-3"
          >
            <Tab eventKey="Destination" title="Destination">
              <ContentRequest type="Destination"/>
            </Tab>
            <Tab eventKey="Flights Destination" title="Flight Destination">
              <ContentRequest type="Flights Destination" />
            </Tab>
            <Tab eventKey="Airlines" title="Airlines">
              <ContentRequest type="Airlines"/>
            </Tab>
            <Tab eventKey="Hotels Destination" title="Hotels Destination">
              <ContentRequest type="Hotels Destination"/>
            </Tab>
            <Tab eventKey="Hotel Detail Page" title="Hotels Detail Page">
              <ContentRequest type="Hotel Detail Page"/>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  )
}
