import React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";

import { LoginPage } from '../Login';
import { DashboardPage } from '../Dashboard';
import { ContentGeneratorPage } from '../ContentGenerator';
import { ContentViewerPage } from '../ContentViewer'
import { PrivateRoute } from '../Components/PrivateRoute'
import { NonPrivateRoute } from '../Components/NonPrivateRoute'

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <NonPrivateRoute><LoginPage /></NonPrivateRoute >,
  },
  {
    path: "/register",
    element: <NonPrivateRoute><LoginPage /></NonPrivateRoute >,
  },
  {
    path: '/dashboard',
    element: <PrivateRoute><DashboardPage /></PrivateRoute >
  },
  {
    path: '/content-generator',
    element: <PrivateRoute><ContentGeneratorPage /></PrivateRoute >
  },
  {
    path: `/content-viewer/:contentID`,
    element: <PrivateRoute><ContentViewerPage /></PrivateRoute >
  }
]);
