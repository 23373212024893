import React from 'react';
import './App.scss';
import {
  RouterProvider,
} from "react-router-dom";
import { router } from './util/router'



function App() {
  return (
    <div className='p-0'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
