import { Badge, Button, Card, Col, Form, Placeholder, Row } from 'react-bootstrap';
import './Dashboard.scss';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDashboardActions } from '../Actions/useDashboardActions';
import { dashboardDataAtom, pollAtom, inProgressDataAtom } from '../state/DashboardState';
import { ContentXNav } from '../Components/ContentXNav';
import { router } from '../util/router';
import Trash from '../images/trash.svg';

export const DashboardPage = () => {
  const dashboardData = useRecoilValue(dashboardDataAtom);
  const inProgressData = useRecoilValue(inProgressDataAtom);
  const navigate = router.navigate
  const [search, setSearch] = useState('')

  const { getDashboardData, getInProgressData, deleteContent } = useDashboardActions()

  const renderStatus = (status: string) => {
    if (status === 'in_progress') {
      return (
        <Placeholder style={{width: '80px'}} lg={12} animation="glow">
          <Placeholder lg={12} />
        </Placeholder>
      )
    }
    if (status.toLowerCase() === 'success') {
      return (
        <span className="text-success text-end" style={{fontWeight: 600}}>Content Generated</span>
      )
    }
    if (status === 'Main Content created') {
      return (
        <span className="text-success text-end" style={{ fontWeight: 600 }}>Standard Content Generated</span>
      )
    }
    if (status.toLowerCase() === 'created') {
      return (
        <span className="text-success text-end" style={{ fontWeight: 600 }}>Created</span>
      )
    }

    if (status === 'failed') {
      return (
        <span className="text-success text-end" style={{ fontWeight: 600 }}>Failed</span>
      )
    }
  }

  useEffect(() => {
    getDashboardData()
  }, [])

  useEffect(() => {
    console.log('here')
    if (inProgressData.length > 0) {
      setTimeout(() => {
        getInProgressData(inProgressData)
      }, 10000);
    }
  }, [inProgressData])

  const openContent = (contentID: string) => {
    navigate(`/content-viewer/${contentID}`)
  }

  const onSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`/dashboard?search=${search}`)
  }

  const deleteContentHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, contentID: string) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Do you really want to delete this content?')) { 
      deleteContent(contentID)
    }
  }

  const filteredDashboardData = useMemo(() => dashboardData.filter(contentJob => contentJob.title.toLowerCase().match(search)), [dashboardData])

  return (
    <div>
      <ContentXNav/>
      <Row style={{marginTop: '80px'}}>
        <Col style={{ marginTop: '50px', marginLeft: '20px' }}>
          <Row className="g-0">
            <h1 style={{ color: '#fcc627' }}>My Content</h1>
          </Row>
          <Row style={{display: 'flex', justifyContent: 'flex-start', textAlign: 'left', marginTop: '20px'}} className="g-0">
            <Col lg={3}>
              <Button href="/content-generator" variant="primary">Create Content</Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col md="auto" className='p-0'>
              {dashboardData.length !== 0 && (
                <Form className="d-flex" onSubmit={onSearch}>
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  {/* <Button variant="primary" onClick={onSearch}>Search</Button> */}
                </Form>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ padding: '30px' }}>
        {filteredDashboardData.length === 0 && <h2>Please generate Content</h2>}
        {filteredDashboardData.map((contentJob) => (
          <Card 
            className={
              `content-card 
              ${contentJob.status === 'created' ? 'content-created' : ''} 
              ${contentJob.status.toLowerCase() === 'success' ? 'content-success' : ''}
              ${contentJob.status === 'failed' ? 'content-error' : ''}`
            }
            onClick={() => openContent(contentJob.contentID)}  
          >
            <Card.Body style={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Card.Title style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{contentJob.title}</span>
                <span style={{ width: '20px', height: '20px' }} onClick={(e) => deleteContentHandler(e , contentJob.contentID)}>
                  <img src={Trash} alt="trash"/>
                </span>
              </Card.Title>
              <Row style={{ marginBottom: '10px' }}>
                <Col className='p-0' lg={12}>
                  <Card.Text>
                    <Badge bg="primary"><span style={{ fontSize: '14px' }}>{contentJob.content_type}</span></Badge>
                  </Card.Text>
                </Col>
              </Row>
              <Row style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems:'center' }}>
                <Col className='p-0' lg={6}>
                  <Card.Text style={{ marginBottom: '0px' }}>
                    <Badge bg="secondary">{contentJob.search_for}</Badge>
                  </Card.Text>
                </Col>
                <Col className='p-0 flex justify-content-end' style={{ display: 'flex' }} lg={6}>
                  {renderStatus(contentJob.status)}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Row>
    </div>
  )
}
