import { Col, NavDropdown, Navbar, Row } from "react-bootstrap"
import { useRecoilValue } from "recoil";
import { userAtom } from '../state/userState'
import { useUserActions } from "../Actions/useUserActions";

export const ContentXNav = () => {
  const user = useRecoilValue(userAtom);
  const { logout } = useUserActions()

  const userData = JSON.parse(user || '')

  return (
    <Row style={{position: 'fixed', width: '100%', top: 0, zIndex: 2}} className='g-0'>
      <Col lg={12}>
        <Navbar style={{ padding: '10px 20px' }} bg="primary" expand="lg">
          <Navbar.Brand style={{ color: '#fcc627', fontSize: '30px', fontWeight: '600' }} href="/dashboard">ContentX</Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <NavDropdown style={{ color: '#fcc627', fontSize: '30px', fontWeight: '600' }} align="end" title={userData.name} id="basic-nav-dropdown">
              {/* <NavDropdown.Item>My Account</NavDropdown.Item> */}
              <NavDropdown.Item onClick={logout}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </Row>
  )
}