import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authAtom } from '../state/authState'

export const PrivateRoute = ({ children}: { children: any }) => {
  const auth = useRecoilValue(authAtom);

  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
