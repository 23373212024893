import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authAtom } from '../state/authState'

export const NonPrivateRoute = ({ children }: { children: any }) => {
  const auth = useRecoilValue(authAtom);

  if (auth) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};
