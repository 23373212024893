import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Accordion, Button, Card, Col, Form, ListGroup, NavDropdown, Navbar, Placeholder, Row, Tab, Tabs } from 'react-bootstrap';
import CopyIcon from '../images/clipboard.svg';
import CopyDoneIcon from '../images/clipboard-check.svg';
import CheckCircle from '../images/check-circle.svg';
import CrossCircle from '../images/x-circle.svg';
import { Link, useParams } from 'react-router-dom';
import { useContentViewerActions } from '../Actions/useContentViewerAction';
import { contentViewerDataAtom } from '../state/contentViewerState';
import { RequestMap, languageMap } from './constants'
import { ContentXNav } from '../Components/ContentXNav';
import { router } from '../util/router';

type ContentDataProps = {
  title: string
  content: string
}

const ContentRequestData = ({ content, translations }: any) => {
  const renderValue = (value: any, key: string) => {
    if (typeof value === 'string' || typeof value === 'number') {
      return (<span>{value}</span>)
    }
    if (typeof value === 'boolean') {
      // eslint-disable-next-line jsx-a11y/alt-text
      return (value ? <img style={{color: 'green'}} src={CheckCircle} alt="true" /> : <img src={CrossCircle} alt="false" />)
    }

    if (key === 'languages' && value.length > 0) {
      return (
        <span>
          {translations.map((link: string) => {
            const splitLink = link.split('-');
            console.log(splitLink[splitLink.length - 1])
            return <Link style={{ marginRight: '5px'}} to={`/content-viewer/${link}`}>{languageMap[splitLink[splitLink.length - 1]]}</Link>
          })}
        </span>
      )
    }
  }

  return (
    <Card style={{ padding: '20px', marginBottom: '20px', width: '33%', height: '500px' }}>
      <Card.Title style={{
        marginBottom: '20px', display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <span>Content Request Data</span>
      </Card.Title>
      <Card.Body style={{ height: '100%', overflowY: 'scroll', whiteSpace: 'pre-line' }}>
        <ListGroup>
          {Object.keys(content).map((contentKey: string) => (
            RequestMap[contentKey] ? ( 
              <ListGroup.Item key={contentKey}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <span>{RequestMap[contentKey]}:</span> <span style={{ fontWeight: 600}}>{renderValue(content[contentKey], contentKey)}</span>
                </div>
              </ListGroup.Item>
            ) : null
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

const SelectorContentViewer = ({ content, title }: any) => {
  const [isCopyDoneMap, setIsCopyDoneMap] = useState({} as Record<string, boolean>)

  useEffect(() => {
    const copyMap = Object.keys(content).reduce((result, contentKey) => {
      return {
        ...result,
        [contentKey]: false,
      }
    }, {})
    setIsCopyDoneMap(copyMap)
  }, [content])

  const copyText = async (key: string, data: string) => {
    await window.navigator.clipboard.writeText(data.replace(/(?:\r\n|\r|\n)/g, '').replace('\n', ''));
    setIsCopyDoneMap({
      ...isCopyDoneMap,
      [key]: true,
    })
  }
  return (
    <Card style={{ padding: '20px', marginBottom: '20px', width: '33%', height: '500px'}}>
      <Card.Title style={{
        marginBottom: '20px', display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <span>{title}</span>
      </Card.Title>
      <Card.Body style={{ height: '100%', overflowY: 'scroll', whiteSpace: 'pre-line'}}>
        <ListGroup>
          {Object.keys(content).map((contentKey: string) => (
            <ListGroup.Item key={contentKey}>
              <div style={{ float: 'right' }}>
                <span style={{ cursor: "pointer" }} onClick={() => copyText(contentKey, content[contentKey])}>
                  {isCopyDoneMap[contentKey] ? <img src={CopyDoneIcon} alt="copy done" /> : <img src={CopyIcon} alt="copy" />}
                </span>
              </div>
              <div>
                {content[contentKey].split('\\n').map((str: string) => <p>{str}</p>)}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

const ContentData = ({ title, content }: ContentDataProps) => {
  const [isCopyDone, setIsContentDone] = useState(false)
  // const [isShowMore, setIsShowMore] = useState(false)

  const copyText = async (data: string) => {
    await window.navigator.clipboard.writeText(data.replace(/(?:\r\n|\r|\n)/g, '').replace('\n', ''));
    setIsContentDone(true)
  }

  // const toggleShowMore = () => {
  //   setIsShowMore(!isShowMore)
  // }

  if (!content) {
    return null
  }

  return (
    <Card style={{ padding: '20px', marginBottom: '20px', width: '33%', height: '500px'}}>
      <Card.Title style={{
        marginBottom: '20px', display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <span>{title}</span>
        <span style={{ cursor: "pointer" }} onClick={() => copyText(content)}>
          {isCopyDone ? <img src={CopyDoneIcon} alt="copy done" /> : <img src={CopyIcon} alt="copy" />}
        </span>
      </Card.Title>
      <Card.Body style={{ height: '100%', overflowY: 'scroll', whiteSpace: 'pre-line'}}>
        {content.split('\\n').map(str => <p>{str}</p>)}
        {/* {content} */}
      </Card.Body>
      {/* {content.length > 1000 && (
        <Card.Link onClick={() => toggleShowMore()}>
          {isShowMore ? 'Show Less' : 'Show More'}
        </Card.Link>
      )} */}
    </Card>
  )
}

export const ContentViewerPage = () => {
  const params = useParams()
  const navigate = router.navigate
  const { getContentData, generateContent } = useContentViewerActions()
  const contentViewerData = useRecoilValue(contentViewerDataAtom);
  let selectorContent;
  
  useEffect(() => {
    if (params?.contentID) {
      getContentData(params?.contentID)
    }
  }, [params?.contentID])
  
  if (!contentViewerData) {
    return <span>loading</span>
  }
  
  const seoContent = 
  contentViewerData.seo_content && contentViewerData.seo_content !== '' 
  ?  JSON.parse(contentViewerData.seo_content) : {}

  try {
    const parsedData = JSON.parse(contentViewerData.selector_content)
    selectorContent = parsedData
  } catch (e) {
    console.log(e)
  }

  const contentRequest = JSON.parse(contentViewerData.request)

  const startCreation = (contentID: string) => {
    generateContent(contentID)
    navigate('/dashboard')
  }

  return (
    <div>
      <ContentXNav />
      <Row style={{ marginTop: '80px' }}>
        <Col style={{ marginTop: '50px', marginLeft: '20px' }}>
          <Row className="g-0">
            <h1 style={{ color: '#fcc627' }}>{contentViewerData.title}</h1>
          </Row>
        </Col>
      </Row>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <ContentRequestData content={contentRequest} translations={contentViewerData.translations.split(',')}/>
        <div style={{marginTop: '20px'}}>
          {contentViewerData.status === 'created' ? (
            <Button onClick={() => startCreation(contentViewerData.contentID)}>Start Creation</Button>
          ) : null}
          {contentViewerData.status === 'in_progress' ? (
            <h3>Please be patient, we are still generating content.</h3>
          ) : null}
        </div>
      </div>
      {contentViewerData.status !== 'created' ? (
        <div style={{ padding: '20px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}} className='g-0'>
          <ContentData title="AI Main Content" content={contentViewerData?.original_main_content} />
          {/* {contentRequest.rewrite_main_content && <ContentData title="Main Content Low" content={contentViewerData?.main_content} />} */}
          {/* <ContentData title="Main Content Medium" content={seoContent?.kws} /> */}
          <ContentData title="Main Content High" content={seoContent?.wdf_idf} />
          <ContentData title="Main Content on Steroids" content={seoContent?.wdf_idf_intention} />
          <ContentData title="SEO Main Content Structured" content={seoContent?.wdf_idf_intention_headlines} />
          {/* <ContentData title="Main Content X" content={seoContent?.wdf_idf_intention_rewritten} /> */}
          {/* <ContentData title="Main Content X2" content={seoContent?.wdf_idf_intention_rewritten_double} /> */}
          {/* <ContentData title="Seo Content" content={contentViewerData?.seo_content} /> */}
          {selectorContent 
            ? <SelectorContentViewer title="Selector Content" content={selectorContent} /> 
            : <ContentData title="Selector Content" content={contentViewerData?.selector_content} />
          }
          <ContentData title="Meta Title & Description" content={seoContent?.title_description} />
          <ContentData title="FAQs" content={seoContent?.faq} />
          <ContentData title="FAQs JSON" content={seoContent?.faq_json} />
        </div>
      ) : null} 
    </div>
  )
}
