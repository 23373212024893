import { atom } from 'recoil';

type ContentViewerType = {
  main_content: string
  seo_content: string 
  selector_content: string
  request: string
  status: string
  original_main_content: string
  title: string
  translations: string
  contentID: string
}

const contentViewerDataAtom = atom({
  key: 'contentViewerData',
  default: null as unknown as ContentViewerType
});

export { contentViewerDataAtom };
