import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useRequestHandler } from "../util/useRequestHandler"
import { authAtom } from '../state/authState'
import { dashboardDataAtom, inProgressDataAtom } from '../state/DashboardState';

export const useDashboardActions = () => {
  const { requestHandler } = useRequestHandler()
  const auth = useRecoilValue(authAtom);
  const setDashboardData = useSetRecoilState(dashboardDataAtom);
  const dashboardData = useRecoilValue(dashboardDataAtom);
  const setInProgressDataAtom = useSetRecoilState(inProgressDataAtom);

  const getDashboardContentIds = async () => {
    try {
      return await requestHandler.get(`/contentx/getDashboard?apikey=${auth}`)
    } catch (e) {
      console.log(e)
    }
  }

  const getContentDataByIds = async (contentIds: string) => {
    const ids = contentIds.split(',').filter(id => id.trim() !== '').map(id => id.trim())

    const requests = ids.map(id => requestHandler.get(`/contentx/getContentInfo?apikey=${auth}&contentID=${id}`))

    try {
      const dashbaordData = await Promise.all(requests)
      return dashbaordData
    } catch (e) {
      console.log(e)
    }
  }

  const getDashboardData = async () => {
    try {
      const response = await getDashboardContentIds()
      const allContent = await getContentDataByIds(response?.data?.contents)

      const contentJobArray = allContent?.map((content: any) => content.data)

      const inProgressContents = contentJobArray?.reduce((result, contentJob) => {
        return contentJob.status === 'in_progress' ? result.concat(contentJob) : result
      }, [])

      if (inProgressContents.length > 0 ) {
        setInProgressDataAtom(inProgressContents)
      }

      if (contentJobArray) {
        setDashboardData(contentJobArray)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getInProgressData = async (inProgressData: any[]) => {
    try {
      const ids = inProgressData.reduce((result, contentData) => {
        return result + contentData.contentID
      }, '');
      if (ids === '') {
        return null
      }
      const allContent = await getContentDataByIds(ids)
      const contentJobArray = allContent?.map((content: any) => content.data)
      const updatedDashboradData = contentJobArray?.reduce((result: any[], contentJob) => {
        const contentJobPosition = result.findIndex(job => job.contentID === contentJob.contentID)
        result[contentJobPosition].contentID = contentJob.contentID
        return result;
      }, JSON.parse(JSON.stringify(dashboardData)))

      if (updatedDashboradData) {
        const inProgressContents = contentJobArray?.reduce((result, contentJob) => {
          return contentJob.status === 'in_progress' ? result.concat(contentJob) : result
        }, [])
        if (inProgressContents.length > 0 && contentJobArray?.length !== inProgressContents.length) {
          setInProgressDataAtom(inProgressContents)
        }
        setDashboardData(updatedDashboradData)
      }


    } catch (e) {
      console.log(e)
    }
  }


  const deleteContent = async (contentId: string) => {
    try {
      await requestHandler.post(`/contentx/deleteContentFromDashboard?apikey=${auth}&contentID=${contentId}`)
      getDashboardData()
    } catch (error) {
      console.log(error)
    }
  }  

  return {
    getDashboardData,
    getInProgressData,
    deleteContent
  }
}