import { atom } from 'recoil';

const dashboardDataAtom = atom({
  key: 'dashboardData',
  default: [] as any[]
});

const pollAtom = atom({
  key: 'isPoll',
  default: false
});

const inProgressDataAtom = atom({
  key: 'inProgressData',
  default: [] as Object[]
});

export { dashboardDataAtom, pollAtom, inProgressDataAtom };
