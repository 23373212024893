import { FormEvent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import logo from '../images/CX.png'
import background from '../images/1View.png'
import { useUserActions } from '../Actions/useUserActions'
import './Login.scss';
import { Link, useLocation } from 'react-router-dom';

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [showNameError, setShowNameError] = useState(false)
  const [passwordSecondary, setPasswordSecondary] = useState('')
  const [showError, setShowError ] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const { login, register } = useUserActions()
  const location = useLocation()

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      await login(email, password)
      setShowError(false)
    } catch (e) {
      if (e) {
        setShowError(true)
      }
    }
  }

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onRegister = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setShowEmailError(false)
    setShowPasswordError(false)
    setShowNameError(false)
    const isValid = validateEmail(email)

    if (!isValid) {
      setShowEmailError(true)
    }

    if (password !== passwordSecondary) {
      setShowPasswordError(true)
    }

    if (!name) {
      setShowNameError(true)
    }

    if (email !== '' && name !== '' && password !== '' && password === passwordSecondary) {
      register(email, password, name)
    }
  }

  return (
    <Row className='login-page'>
      <Col lg={6} className='p-0'>
        <div className="background-image">
          <img src={background} alt="background" />
        </div>
      </Col>
      <Col lg={6}>
        <Row style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Col lg={8} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img style={{ width: '400px', height: '400px' }} src={logo} alt="logo" />
            {location.pathname === '/login' ? (
              <Form style={{width: '70%'}} onSubmit={(e) => onSubmit(e)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} isInvalid={showError} />
                  {showError && (
                    <Form.Control.Feedback type="invalid">
                      Credentials are not correct
                    </Form.Control.Feedback>
                  )}
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} isInvalid={showError} />
                  {showError && (
                    <Form.Control.Feedback type="invalid">
                      Credentials are not correct
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                  <Button onClick={(e) => onSubmit(e)}  variant="primary" type="submit">
                    Login
                  </Button>
                  <Link to="/register"> Are you Registered ? </Link>
                </div>
              </Form>
            ) : null}
            {location.pathname === '/register' ? (
              <Form style={{ width: '70%' }} onSubmit={(e) => onRegister(e)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="email" placeholder="Enter Name" value={name} onChange={e => setName(e.target.value)} isInvalid={showNameError} />
                  {showNameError && (
                    <Form.Control.Feedback type="invalid">
                      Please provide name
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} isInvalid={showEmailError} />
                  {showEmailError && (
                    <Form.Control.Feedback type="invalid">
                      Please provide valid email
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} isInvalid={showPasswordError} />
                  {showPasswordError && (
                    <Form.Control.Feedback type="invalid">
                      Password don't match
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Repeat Password</Form.Label>
                  <Form.Control type="password" placeholder="Repeat Password" value={passwordSecondary} onChange={e => setPasswordSecondary(e.target.value)} isInvalid={showPasswordError} />
                  {showPasswordError && (
                    <Form.Control.Feedback type="invalid">
                      Password don't match
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Button onClick={(e) => onRegister(e)} variant="primary" type="submit">
                  Register
                </Button>
              </Form>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
